@import './../../../assets/scss/1-variables/app';
@import './../../../assets//scss/7-functions/units';

.account-title {
  font-size: $font-size-xl;
  color: $gray-35;
}
.bulk-upload-text {
  color: $link-blue;
  font-size: $font-size-sm;
  font-weight: $headings-font-weight;
  margin-top: rem(4px);
  cursor: pointer;
}
.bulk-upload-text > a:hover {
  color: $link-blue;
  text-decoration: none;
}
.cursor-pointer {
  cursor: pointer;
}
.upload-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: rem(2px) dotted $gray-400;
  border-radius: rem(4px);
  background: rgba(13, 113, 198, 0.1);
  font-size: $font-size-lg;
  font-style: $font-style-italic;
  color: $gray-650;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;
}
.upload-box-border-blue {
  border: rem(2px) dotted $link-blue;
}
.upload-box-border-red {
  border: rem(2px) dotted $danger;
}
.non-italic {
  font-style: normal;
}
.file-name {
  font-size: $font-size-base;
  color: $link-blue;
}
.truncate {
  display: inline-block;
  max-width: 85%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
}
.file-checkbox input[type='checkbox'] {
  width: 100%;
  height: 100%;
  margin-top: rem(2px);
}
.progress {
  height: rem(6px);
}
.progress-bar {
  background-color: $gray-650;
  width: 100%;
}
.grid-wrapper {
  position: relative;
  display: grid;
  grid-template-columns: 92% auto;
  gap: rem(7px);
}
.percentage-block {
  font-size: $font-size-base;
  font-weight: $headings-font-weight;
  text-align: right;
  position: relative;
}
.upload-btn {
  font-size: $font-size-sm;
  letter-spacing: rem(1px);
  padding-left: rem(30px);
  padding-right: rem(30px);
  margin-top: 0.5rem;
}
.cross-icon {
  margin-top: 0;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 1;
}
.percentage-text {
  position: relative;
  top: rem(16px);
}

.error-bulk-upload-row {
  // background-color: rgb(255 7 0 / 68%);
  background-color: rgb($red, 50%);
}
.success-bulk-upload-row {
  // background-color: rgb(255 7 0 / 68%);
  background-color: rgb($green, 50%);
}
.success-message-text {
  font-weight: 800;
  text-align: center;
}

.error-message-text {
  color: $red;
  margin-top: 0.75rem;
}

.bulk-upload-download-icon {
  margin-bottom: 0.5rem;
}

.label-gray {
  color: $gray-700;
}
.label-red {
  color: $red;
}
@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}
.shake {
  animation: shake 0.3s;
}
.uploaded-selected-user-row {
  background-color: #98fb98 !important;
}

.label-mt-2 {
  margin-top: 2px;
}
