@import './../../assets/scss/1-variables/app';
@import './../../assets/scss/7-functions/_units';

.react-checkbox-tree {
  ol {
    li {
      margin-top: rem(6);
      margin-bottom: rem(6);
      ol {
        li {
          margin-top: rem(6);
          margin-bottom: rem(6);
        }
      }
    }
  }
}

.react-checkbox-tree label {
  display: flex;
  align-items: center;
}

.react-checkbox-tree label:hover {
  background: none !important;
}

.dropdown-checkbox-icon {
  height: rem(23) !important;
  width: rem(23) !important;
  margin-left: rem(-6) !important;
}

.dropdown-arrow-icon {
  height: rem(20) !important;
  width: rem(20) !important;
}

.dropdown-down-arrow-icon {
  height: rem(20) !important;
  width: rem(20) !important;
  transform: rotate(90deg);
}

.react-checkbox-tree {
  color: $gray-700;
}

.rct-title {
  padding: 0 !important;
  position: relative;
  top: rem(1);
  overflow-wrap: anywhere;
}
