@import './../../assets/scss/1-variables/app';

.logout-contanier {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.logout-text {
  margin-bottom: 7rem;
}

.login-button {
  position: absolute;
  background-color: $footer-background;
  border-radius: 3px;
  border: 1px solid #000000;
  display: block;
  text-decoration: none !important;
  color: #000000;
  font-size: 17px;
  padding: 10px 20px;
  margin: 0;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.logout-button {
  position: absolute;
  background-color: $footer-background;
  border-radius: 3px;
  border: 1px solid #000000;
  display: block;
  color: #000000;
  font-size: 17px;
  padding: 10px 20px;
}
