@import './../../assets/scss/1-variables/app';

.dashboard-header {
  display: flex;
  align-items: center;
  font-family: $base-font-family;
  font-style: $font-style-normal;
  font-weight: $headings-font-weight;
  font-size: $font-size-xl;
  line-height: $line-height-lg;
  color: $gray-650;
  border-top: rem(1px) solid $gray-550;
  padding: rem(16px) 0rem 0rem 0rem;

  .user-role {
    font-size: $font-size-xl;
    margin-left: rem(4px);
  }
}

.user-details {
  padding-top: 2rem;
}

@mixin text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inherit;
}

.user-blue {
  color: $link-blue;
}

.user-name-wrapper {
  @include text-ellipsis;
}

.user-key {
  font-family: $base-font-family;
  font-weight: $headings-font-weight;
  font-size: rem(13);
  line-height: $line-height-sm;
  color: $black;
}

.user-value {
  @include text-ellipsis;
  font-family: $base-font-family;
  font-weight: $font-weight-normal;
  font-size: rem(14);
  line-height: $line-height-lg;
  color: $black;
}

@media only screen and (max-width: 992px) {
  .user-value.support {
    white-space: inherit;
  }
}

.user-clickable-content {
  display: flex;
  .user-content-wrapper {
    padding: 0px 16px;
    border-right: 1px solid $gray-550;

    &:last-child {
      border-right: none;
    }
  }

  .user-clickable-link {
    border-bottom: 0.0625rem solid $gray-550;
    padding-bottom: 5rem;
    width: 100%;
  }

  .user-clickable-icon {
    width: rem(16px);
    height: rem(16px);

    font-style: $font-style-normal;
    font-weight: $font-weight-normal;
    font-size: $font-size-xl;
    line-height: $line-height-xsm;
    text-align: center;
    letter-spacing: $letter-spacing-md;
    cursor: pointer;
  }

  .user-clickable-txt {
    font-family: $base-font-family;
    font-style: $font-style-normal;
    font-weight: 400;
    font-size: $font-size-lg;
    line-height: $line-height-sm;
    letter-spacing: $letter-spacing-md;
    color: $link-blue;
    margin-left: 0.5rem;
    padding-right: rem(16px);
    border-right: 1px solid $gray-550;
    cursor: pointer;
  }
}

.user-dashboard-footer {
  position: relative;
  padding-top: 1rem;
  height: rem(305px);
}

.user-buildings-img {
  width: rem(801px);
  height: rem(347px);
  position: absolute;
  right: 0;
  bottom: 0;
}

.user-content {
  padding: 2.5rem 0 0 0;
  flex: 1 1;
  direction: ltr;
  background-color: $white;
  width: 100%;
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

.user-history-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: rem(8);
}

.user-right-section {
  padding-top: 0.25rem;
  background: $color-tint-grey;
  border-radius: 0.3rem;
  box-shadow: $box-shadow-2xl;

  .user-history-title {
    font-size: $font-size-lg;
  }

  .user-history-description {
    font-size: $font-size-sm;
  }
}

.user-bell-icon {
  height: rem(16);
  margin-top: rem(3);
}

.user-history {
  font-family: $base-font-family;
  font-size: $font-size-base;
  letter-spacing: $letter-spacing-md;
  color: $spg;
  cursor: pointer;
}

.spg-red {
  color: $spg;
}

.user-sub-header {
  min-width: rem(130px);
  height: rem(32px);
  font-family: $base-font-family;
  font-style: $font-style-normal;
  font-weight: $font-weight-normal;
  font-size: $font-size-2xl;
  line-height: $line-height-xl;
  letter-spacing: $letter-spacing-md;
  color: $spg;
}

.user-info-sub-header {
  min-width: rem(130px);
  margin-top: rem(2px);
  margin-bottom: rem(4px);
  font-family: $base-font-family;
  font-style: $font-style-normal;
  font-weight: $font-weight-normal;
  font-size: $font-size-2xl;
  letter-spacing: $letter-spacing-md;
  color: $spg;
}

.user-info-form-label {
  font-family: $base-font-family;
  font-style: $font-style-normal;
  font-weight: $font-weight-normal;
  font-size: $font-size-base;
  line-height: $line-height-xsm;
  margin-top: rem(3px);
  margin-bottom: -1px;
  color: $gray-35;
}

// Bootstrap Switch
.form-switch-custom {
  display: flex;
  height: inherit;
  min-height: 1.33rem;
  padding-left: 0;
  margin-bottom: 0;
}

.form-check-input-custom {
  margin: auto !important;
}

.form-check-input-custom:disabled {
  pointer-events: all;
  cursor: not-allowed;
}

.contract-description-field {
  width: 200%;
}

.form-check {
  display: inline-flex;
  align-items: center;
  margin-right: 25px;
}

.form-check:last-of-type {
  margin-right: 0;
}

.form-check-label {
  margin-top: 5px;
  margin-left: 5px;
}
