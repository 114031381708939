h1,
h2,
h3,
h4,
h5,
h6 {
  @extend %elementreset;
  @include font-akk(bold);
  margin-bottom: rem(20);
}

.h0 {
  margin-bottom: rem(25);
  font-size: rem(36);
  letter-spacing: rem(-2);
  line-height: $line-height-sm;

  @include breakpoint(tabletPortrait) {
    margin-bottom: rem(40);
    font-size: rem(60);
    line-height: $line-height-xsm;
  }

  @include breakpoint(desktop) {
    font-size: rem(72);
  }
}

h1,
.h1 {
  margin-bottom: rem(25);
  font-size: $h1-font-size;
  letter-spacing: rem(-1);
  line-height: $line-height-sm;

  @include breakpoint(tabletPortrait) {
    font-size: $font-size-xxl;
  }

  @include breakpoint(tabletLandscape) {
    font-size: rem(48);
  }
}

h2,
.h2 {
  margin-bottom: rem(25);
  font-size: rem(28);
  letter-spacing: 0;
  line-height: $line-height-sm;

  @include breakpoint(tabletLandscape) {
    font-size: rem(36);
    letter-spacing: rem(-1);
  }
}

h3,
.h3 {
  font-size: $font-size-xl;
  letter-spacing: 0;
  line-height: $line-height-sm;

  @include breakpoint(tabletLandscape) {
    font-size: rem(24);
  }
}

h4,
.h4 {
  font-size: $font-size-xl;
  letter-spacing: 0;
  line-height: $line-height-sm;

  @include breakpoint(tabletLandscape) {
    font-size: $font-size-xl;
  }
}

h5,
.h5 {
  font-size: $font-size-xl;
  letter-spacing: 0;
  line-height: $line-height-sm;
}

h6,
.h6 {
  font-size: $font-size-lg;
  letter-spacing: 0;
  line-height: $line-height-sm;
}

//  Uppercase subheading
.subheading {
  @include font-akk(bold);

  margin-bottom: rem(20);
  font-size: $font-size-base;
  letter-spacing: 1px;
  line-height: $line-height-lg;
  padding-bottom: rem(12);
  text-transform: uppercase;
  width: 100%;

  &--with-border {
    border-bottom: rem(2) solid $black;
  }

  @include breakpoint(tabletLandscape) {
    line-height: $line-height-base;
  }
}

.info-heading {
  font-family: $base-font-family;
  font-weight: $font-weight-bold;
  margin-top: rem(5px);
  margin-bottom: 0;
  font-size: $font-size-base;
  letter-spacing: $letter-spacing-md;
  line-height: $line-height-lg;
  width: 100%;
}
