.text-sm {
  font-size: $font-size-sm;
}

.text-lg {
  font-size: $font-size-lg;
}

b,
strong,
.font-weight-bold {
  font-weight: $font-weight-bold;
  color: $text-bold-color;
}

pre.snippet {
  .hljs {
    background: $static-dark;
    border-radius: $border-radius-lg;
    padding: $spacer;
  }
}

a {
  cursor: pointer;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1{
  font-weight: 600;
  color:$dark;
}