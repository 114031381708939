@import './../../assets/scss/1-variables/app';

.filter-panel {
  position: relative;
  border: 1px solid $gray-550;
  border-radius: 3px;
  margin: 0.5rem 0 0 0;
  // height: calc(100vh - 185px);
}
.filter-font {
  font-size: 0.8em;
}

.filter-dropdowns {
  overflow-x: hidden;
  overflow-y: hidden;
  min-height: calc(100vh - 265px);
}
.filter-dropdowns .entitlements-wrapper > .btn {
  font-size: 0.8rem;
  white-space: normal;
  padding: 4px;
}
.addCriteriaDD .dropdown > .btn {
  font-size: 0.8rem;
}
.filter-dropdowns .add-entitlements-block > .add-entitlements-dropdown {
  font-size: 0.7rem;
  margin-top: -1.5%;
  margin-left: -2%;
}
.filter-dropdowns
  .add-entitlements-block
  > .add-entitlements-dropdown
  > .add-entitlements-title {
  margin-left: -2.5%;
}
.filter-dropdowns
  .add-entitlements-block
  > .add-entitlements-dropdown
  > input[type='checkbox'] {
  width: 0.8rem;
  height: 0.8rem;
  margin-bottom: 1px;
}
.addCriteriaDD {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.addCriteriaDD > .custom-dropdown > .dropdown > .btn-light {
  width: 100%;
  background: #fff;
  text-align: left;
  color: #242424;
  font-weight: bold;
  border: 2px solid #eff2f6;
  padding-left: 0.23rem;
  padding: 4px 7px;
}
.addCriteriaDD > .custom-dropdown > .dropdown > .btn-light:focus {
  box-shadow: none;
}

.addCriteriaDD > .custom-dropdown {
  width: 73%;
}

.position-unset {
  position: unset !important;
}

.downloadIconTextGrayOutColour {
  color: #bfbfbf;
}

.downloadIconTextColor {
  color: #242424;
}

.filterIcon {
  margin-bottom: 2px;
}

.filterIconText {
  font-weight: 500;
  font-size: 14px !important;
}

.filterButton {
  font-size: 12px;
  padding: 0px;
}

.newCriteria {
  position: absolute;
  width: 95%;
  bottom: 0;
}

.addCriteriaText {
  font-size: 0.7rem;
  font-weight: bold;
  cursor: pointer;
}
.addCriteriaDD > .custom-dropdown > .dropdown > button > .label-truncate {
  max-width: 89%;
}
.cross-icon {
  position: relative;
  right: -3px;
}
.separator {
  background: #e3e3ee;
  height: 1.75rem;
  width: 0.04rem;
}

.drag-icon {
  margin-left: 10px;
  margin-top: 3px;
  fill: black;
}

.filter-dropdown-pr1 {
  padding-right: 1px;
}

.report-mb-3 {
  margin-bottom: 10px;
}

.addCriteriaDD {
  .account-label {
    font-size: 0.7rem;
  }
  .account-dropdown {
    font-size: 10px;
    padding: 2px 4px;
  }
}

.disabledWrapper {
  cursor: not-allowed;
  display: inline-block;
}

.report-parent .dropdown > button > svg {
  top: 0.1rem !important;
}

// /* For above 1024 Resolution monitor */
@media only screen and (min-width: 1281px) {
  .filter-dropdowns {
    height: calc(100vh - 383px);
  }
  .addCriteriaDD > .custom-dropdown {
    margin-bottom: 0.5rem !important;
  }
}

/* (2048x1536) 125% resolution */
@media only screen and (min-device-width: 1536px) and (max-device-width: 2048px) {
  .filter-dropdowns {
    height: calc(100vh - 390px);
  }
}

@media only screen and (min-width: 2100px) {
  .filter-dropdowns {
    height: calc(100vh - 370px);
  }
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
