.location-modal-header {
    border-bottom: 0;
    padding: 0.5rem;
}

.loaction-modal-body {
    padding-top: 0.5rem;

    &>h2 {
        font-weight: 600;
        margin-bottom: 0.75rem;
    }

    &>h3 {
        font-weight: $font-weight-normal;
    }
}

.location-modal-footer {
    &>button:first-child {
        margin-right: 2.5rem;
    }
}

.modal-90w {
    width: 90%;
    max-width: none !important;
}