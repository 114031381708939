// Font mixins

@mixin base-font() {
    font-size: $font-size-base;
    line-height: $line-height-base;

    @include breakpoint(tabletLandscape) {
        font-size: $font-size-xl;
        line-height: $line-height-lg;
    }
}

@mixin font-akk($type) {
    font-family: $base-font-family;

    @if $type == 'light' {
        font-weight: 300;
    }
    @else if $type == 'regular' {
        font-weight: 400;
    }
    @else if $type == 'bold' {
        font-weight: 700;
    }
}
