.ug-save-btn {
  position: absolute;
  bottom: 10px;
}
.user-id-save-btn {
  position: relative;
  top: 25px;
}

.alert-width-45 {
  max-width: 45rem !important
}