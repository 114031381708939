.title {
  color: $red;
  font-size: em(24);
}

.entitlements-block {
  min-height: 100vh;
}

.starter-text {
  text-align: center;
  margin-top: rem(50);
}
.ag-grid-add-entitlement-table {
  margin-top: $spacer * 0.75;
}

.ag-grid-add-entitlement-table {
  height: calc(100vh - 295px);
  .ag-theme-alpine {
    height: auto !important;
    .ag-root-wrapper {
      height: 100% !important;
    }
  }
}

.ag-grid-export-entitlement-table {
  height: calc(100vh - 325px);
  .ag-theme-alpine {
    height: auto !important;
    .ag-root-wrapper {
      height: 100% !important;
    }
  }
}

.add-entitlements {
  margin-bottom: rem(5);
  text-transform: uppercase;
  color: $gray-600;

  svg {
    vertical-align: sub;
  }

  a:hover {
    color: $gray-600;
    text-decoration: none;
  }
}

.add-entitlements.left-0 {
  left: rem(14);
  text-transform: capitalize;
  font-size: $font-size-xl;
}

.dropdown > button > svg {
  float: right;
  position: relative;
  top: rem(5);
}

.entitlements-wrapper {
  .dropdown-menu.show {
    width: 100%;
    max-height: 19rem;
    overflow-y: scroll;
  }
}

.add-entitlements-block > .dropdown-item {
  padding: 0.35rem 1rem;

  &:hover {
    background: none;
  }

  &:focus {
    background: none;
  }

  &:active {
    background: none;
    color: $gray-600;
  }
}

.add-entitlements-title {
  overflow-wrap: break-word;
  display: inline-block;
  min-width: 14rem;
  padding-left: 0.75rem;
}

.add-entitlements-dropdown {
  display: flex;
  align-items: center;
  font-size: $font-size-base;
  padding: 0.35rem 1rem;
  color: $gray-700;
}

.add-entitlements-dropdown > input[type='checkbox'] {
  height: rem(18);
  min-width: rem(18);
}

.entitlements-block > .tab-content {
  margin: rem(10);
}

.action-item-bar {
  font-size: $font-size-sm;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: end;
}

.font-weight7 {
  font-weight: 700;
}

.add-user-entitlements-label {
  top: 1px;
  position: relative;
}

a.add-entitlements-link {
  cursor: pointer;
  color: $link-blue !important;
  display: inline-flex;
  text-decoration: none;
}

.entitlemets-bundles-content {
  text-align: center;
  position: relative;
  padding-top: 2rem;
}

.grid-cell-left {
  text-align: left;
}

.select-all-entitlement-checkbox {
  width: 1rem;
  height: 0.95rem;
  margin-right: 12px;
  outline: auto;
  color: #999;
  margin-left: 1px;
  opacity: 0.9;
}
.breadcrumb-nav-wrapper {
  display: flex;
  align-items: center;
}
.breadcrumb-nav {
  font-size: $font-size-xl;
  padding-left: rem(5);
  max-width: 43%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.checkbox-disable {
  input {
    cursor: not-allowed !important;
  }
  background-color: rgba(59, 59, 59, 0.08) !important;
  color: rgb(170, 170, 170) !important;
  border-color: rgba(59, 59, 59, 0.3) !important;
}
