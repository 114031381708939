.card {
  margin-bottom: 0px;
}

.card-default {
  min-width: 17.22rem;
  height: 19.875rem;
  left: 0rem;
  top: 0rem;
  overflow: hidden;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: calc(24.975% - 0.9375rem);
  max-width: calc(24.975% - 0.9375rem);
  flex-basis: calc(24.975% - 0.9375rem);

  &:after {
    background-color: $white;
    bottom: 0;
    content: '';
    height: 0;
    left: 0;
    position: absolute;
    transition: height 3.25s cubic-bezier(0.17, 0.67, 0.69, 0.85),
      border-top 50ms ease-in-out 0.1s;
    transition-duration: 3.25s, 50ms;
    transition-timing-function: cubic-bezier(0.17, 0.67, 0.69, 0.85),
      ease-in-out;
    transition-delay: 0s, 0.1s;
    transition-property: height, border-top;
    width: 100%;
    z-index: 2;
    flex-grow: 1;
  }

  &:hover {
    .tile-main-header {
      font-family: $base-font-family;
      font-style: $font-style-normal;
      font-weight: 700;
      font-size: $font-size-lg;
      line-height: $line-height-lg;
      color: $black;
      letter-spacing: 0;
      margin-top: 1rem;
      transform: translateY(rem(-96px));
      transition: 0.2s ease-in-out;
      z-index: 3;
    }

    .tile-flip {
      transform: translateY(0%);
    }
  }
}

.tile-flip {
  width: 100%;
  height: rem(318px);
  background: #03446a;
  height: 100%;
  color: white;
  transform: translateY(100%);
  transition: 0.25s;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: rem(16px);
  gap: rem(8px);
  background: $white;
  border-top: rem(4px) solid $black;
  z-index: 2;
  position: absolute;
}

.tile-flip-body {
  width: rem(262px);
  height: rem(230px);

  font-family: $base-font-family;
  font-style: $font-style-normal;
  font-weight: 700;
  font-size: $font-size-xl;
  line-height: $line-height-lg;

  color: $black;
  padding-top: rem(64px);
}

.btn-dataset {
  color: white;
  padding: rem(8px);
  width: 100%;
  height: rem(48px);
  background: $black;

  font-family: $base-font-family;
  font-style: $font-style-normal;
  font-weight: 700;
  font-size: $font-size-base;
}

.tile-flip-description {
  font-family: $base-font-family;
  font-style: $font-style-normal;
  font-weight: $font-weight-normal;
  font-size: $font-size-base;
  // height: rem(128);
  line-height: $line-height-lg;
  color: $black;
  display: -webkit-box;
  // max-width: rem(225);
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  position: absolute;
  left: 1.5rem;
  right: 1.5rem;
  top: 4.5rem;
}

.tile-header {
  max-width: 90%;
  height: 1.188rem;
  font-family: $base-font-family;
  font-style: $font-style-normal;
  font-weight: 700;
  font-size: $font-size-lg;
  line-height: $line-height-sm;
  color: $white;
  position: absolute;
  left: 1.5rem;
  right: 1.5rem;
  top: 3.651rem;
  bottom: 15.037rem;
  z-index: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.tile-border {
  content: '';
  display: block;
  background-color: $spg;
  height: 0.12rem;
  top: 5.25rem;
  position: absolute;
  z-index: 1;
  width: 85%;
  left: 1.5rem;
}

.tile-main-header {
  max-width: 86%;
  height: 3.625rem;

  font-family: $base-font-family;
  font-style: $font-style-normal;
  font-weight: 700;
  font-size: $font-size-xl;
  color: $white;
  position: absolute;
  left: 1.5rem;
  right: 1.5rem;
  top: 5.838rem;
  bottom: 10.412rem;
  z-index: 2;
}

.spg_logo {
  height: 2.45rem;
  position: absolute;
  left: 1.5rem;
  right: 10.188rem;
  top: 16.088rem;
  bottom: 2.358rem;
  z-index: 2;
}

.round_tick {
  width: 1.2rem;
  height: 1.2rem;
  position: absolute;
  right: 9%;
  bottom: 2.437rem;
}

.recent-added {
  background: $spg;
  left: 0;
  position: absolute;
  top: 0;
  align-items: center;
  color: #fff;
  display: inline-flex;
  font-size: 0.875rem;
  padding: 0.5rem 1.1875rem;
  text-align: center;
  white-space: nowrap;
  z-index: 1;
}

.recent-enhanced {
  background: #6986b0;
  left: 0;
  position: absolute;
  top: 0;
  align-items: center;
  color: #fff;
  display: inline-flex;
  font-size: 0.875rem;
  padding: 0.5rem 1.1875rem;
  text-align: center;
  white-space: nowrap;
  z-index: 1;
}

.recent-added svg {
  height: 1.125rem;
  width: 1.125rem;
  margin-right: 0.625rem;
  margin-top: -0.125rem;
}

.recent-enhanced svg {
  height: 0.9375rem;
  width: 0.9375rem;
  margin-right: 0.625rem;
  margin-top: -0.125rem;
}

.tile-bg-img {
  position: absolute;
  height: 100%;
  width: 100%;
}

.image-gradient {
  max-width: 100%;
  width: 800px;
  margin: auto;
}

.image-gradient::after {
  display: block;
  position: relative;

  height: 19.875rem;
  width: 100%;
  content: '';
}
.image-gradient-background::after {
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.9) 45%,
    #000 60%
  );
}

.image-gradient-background-2::after {
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.3) 40%,
    rgba(0, 0, 0, 0.9) 75%
  );
}

.tile-disabled {
  width: 100%;
  height: 100%;
  position: absolute;
  background: $tile-disabled;
  z-index: 2;
  svg {
    width: 12%;
    position: absolute;
    top: rem(8);
    right: rem(5);
    fill: white;
  }
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 3.25rem;
}

@include breakpoint(mobile) {
  .card-default {
    flex-basis: calc(99.975% - 0.9375rem);
    max-width: calc(99.975% - 0.9375rem);
  }
}

@include breakpoint(tabletPortrait) {
  .card-default {
    flex-basis: calc(49.975% - 0.9375rem);
    max-width: calc(49.975% - 0.9375rem);
  }
}

@include breakpoint(tabletLandscape) {
  .card-default {
    flex-basis: calc(32.975% - 0.9375rem);
    max-width: calc(32.975% - 0.9375rem);
  }
}

@include breakpoint(desktop) {
  .card-default {
    flex-basis: calc(24.975% - 0.9375rem);
    max-width: calc(24.975% - 0.9375rem);
  }
}
