@import './../../assets/scss/1-variables/app';

.search-filter-input-div {
  margin-left: 1rem;
  margin-top: 0.5rem;
}

.add-location-div {
  float: right;
}

.modal-backdrop {
  display: none;
}

.modal {
  background: rgba(0, 0, 0, 0.5);
}

.add-location {
  margin-right: 1rem;
  color: $link-blue;
  font-weight: $font-weight-normal;
  font-size: $font-size-sm;
}

.add-location:hover {
  cursor: pointer;
}

.add-location-logo {
  margin-bottom: 0.5rem;
}

.location-form-title {
  width: 100%;
  font-family: $base-font-family;
  font-style: $font-style-normal;
  font-weight: $font-weight-normal;
  font-size: $font-size-xl;
  letter-spacing: $letter-spacing-md;
}

.location-search-filter-input {
  padding: 0.5rem;
}

.add-location-group-label {
  color: $link-blue;
  display: inline-flex;

  &:hover {
    cursor: pointer;
  }
}
.add-location-label {
  top: 1px;
  position: relative;
}
input[type='text'].search-box::-webkit-input-placeholder {
  font-style: $font-style-italic;
}
input[type='text'].ag-text-field-input::-webkit-input-placeholder {
  font-style: $font-style-italic;
}
.select-all-location-checkbox {
  width: 1rem;
  height: 0.95rem;
  margin-right: 12px;
  outline: auto;
  color: #999;
  margin-left: 1px;
  opacity: 0.9;
}
.address-wrapper {
  .address-suggestion {
    font-size: $spacer;
    font-weight: $headings-font-weight;
  }
  .address-block {
    display: flex;
    font-size: 0.85rem;
    border-bottom: 1px solid $color-grey-80;
    padding-top: 0.75rem;
    padding-bottom: 0.6rem;
  }
  .address-block:last-child {
    border-bottom: 0px;
  }
}
.header-title-txt {
  font-size: $font-size-xl;
  font-weight: $font-weight-bold;
}
.address-height-fix {
  max-height: 10rem;
  overflow: auto;
}
.click-link {
  position: relative;
  top: 0.125rem;
  left: 0.313rem;
  color: $blue;
  cursor: pointer;
}
.nonclick-text {
  position: relative;
  top: 0.125rem;
  margin-left: 0.35rem;
}
