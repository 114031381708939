@mixin modal-variant($color) {
  .modal-content {
    background: $color;
    color: color-yiq($color);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    color: $white;
  }
}

/* For 1024 Resolution laptop */
@media only screen and (min-width: 1025px) {
  .ag-grid-modal-content {
    height: rem(261);
    .ag-theme-alpine {
      height: auto !important;
      .ag-root-wrapper {
        height: 100% !important;
      }
    }
  }
  .ag-grid-modal-content.user-id-tab {
    height: rem(301.5);
  }
  .ag-grid-modal-content.welcome-email-tab {
    height: rem(316);
  }
  .user-details-fix {
    margin-top: rem(5.5);
  }
}

/* For above 1024 Resolution monitor */
@media only screen and (min-width: 1281px) {
  .ag-grid-modal-content {
    height: rem(358.5);
  }
  .ag-grid-modal-content.user-id-tab {
    height: rem(399);
  }
  .ag-grid-modal-content.welcome-email-tab {
    height: rem(413.5);
  }
  .user-details-fix {
    margin-top: rem(18.5);
  }
}

/* (2048x1536) 125% resolution */
@media only screen and (min-device-width: 1536px) and (max-device-width: 2048px) {
  .ag-grid-modal-content {
    height: rem(357);
  }
  .ag-grid-modal-content.user-id-tab {
    height: rem(397);
  }
  .ag-grid-modal-content.welcome-email-tab {
    height: rem(411.5);
  }
}

@media only screen and (min-width: 2100px) {
  .ag-grid-modal-content {
    height: rem(366);
  }
  .ag-grid-modal-content.user-id-tab {
    height: rem(405);
  }
  .ag-grid-modal-content.welcome-email-tab {
    height: rem(419.5);
  }
}
