// Heading font (Light)
@font-face {
    font-display: swap;
    font-family: 'Akk Pro';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/akk-pro-light/akk-pro-light.woff2') format('woff2'),
        url('../fonts/akk-pro-light/akk-pro-light.woff') format('woff'),
        url('../fonts/akk-pro-light/akk-pro-light.ttf') format('truetype'),
        url('../fonts/akk-pro-light/akk-pro-light.svg#akk-pro-light') format('svg');
}

@font-face {
    font-display: swap;
    font-family: 'Akk pro';
    font-style: normal;
    font-weight: light;
    src: url('../fonts/akk-pro-light/akk-pro-light.woff2') format('woff2'),
        url('../fonts/akk-pro-light/akk-pro-light.woff') format('woff'),
        url('../fonts/akk-pro-light/akk-pro-light.ttf') format('truetype'),
        url('../fonts/akk-pro-light/akk-pro-light.svg#akk-pro-light') format('svg');
}

// Body font (REGULAR)
@font-face {
    font-display: swap;
    font-family: 'Akk pro';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/akk-pro-regular/akk-pro-regular.woff2') format('woff2'),
        url('../fonts/akk-pro-regular/akk-pro-regular.woff') format('woff'),
        url('../fonts/akk-pro-regular/akk-pro-regular.ttf') format('truetype'),
        url('../fonts/akk-pro-regular/akk-pro-regular.svg#akk-pro-regular') format('svg');
}

@font-face {
    font-display: swap;
    font-family: 'Akk pro';
    font-style: normal;
    font-weight: regular;
    src: url('../fonts/akk-pro-regular/akk-pro-regular.woff2') format('woff2'),
        url('../fonts/akk-pro-regular/akk-pro-regular.woff') format('woff'),
        url('../fonts/akk-pro-regular/akk-pro-regular.ttf') format('truetype'),
        url('../fonts/akk-pro-regular/akk-pro-regular.svg#akk-pro-regular') format('svg');
}

// Heading font (BOLD)
@font-face {
    font-display: swap;
    font-family: 'Akk pro';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/akk-pro-bold/akk-pro-bold.woff2') format('woff2'),
        url('../fonts/akk-pro-bold/akk-pro-bold.woff') format('woff'),
        url('../fonts/akk-pro-bold/akk-pro-bold.ttf') format('truetype'),
        url('../fonts/akk-pro-bold/akk-pro-bold.svg#akk-pro-bold') format('svg');
}

@font-face {
    font-display: swap;
    font-family: 'Akk Pro';
    font-style: normal;
    font-weight: bold;
    src: url('../fonts/akk-pro-bold/akk-pro-bold.woff2') format('woff2'),
        url('../fonts/akk-pro-bold/akk-pro-bold.woff') format('woff'),
        url('../fonts/akk-pro-bold/akk-pro-bold.ttf') format('truetype'),
        url('../fonts/akk-pro-bold/akk-pro-bold.svg#akk-pro-bold') format('svg');
}
