@import './../../assets/scss/1-variables/app';
@import './../../assets/scss/7-functions/_units';
.multi-select-dropdown-wrapper {
  .dropdown-menu.show {
    width: 100%;
    height: 19rem;
    overflow-y: scroll;
  }
}

.multi-select-dropdown-block > .dropdown-item {
  padding: 0.35rem 1rem;

  &:hover {
    background: none;
  }

  &:focus {
    background: none;
  }

  &:active {
    background: none;
    color: $gray-600;
  }
}

.multi-select-dropdown-title {
  white-space: normal;
  display: inline-block;
  width: 17rem;
}

.multi-select-dropdown {
  display: flex;
  align-items: center;
  font-size: $font-size-base;
  padding: 0.35rem 1rem;
  color: $gray-700;
}

.multi-select-dropdown > input[type='checkbox'] {
  height: rem(18);
  width: rem(18);
  margin-right: 0.75rem;
}

.checkbox-selection-disabled {
  cursor: not-allowed;
}
