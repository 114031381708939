.main {
  display: flex;
  width: 100%;
  min-height: 100vh;
  min-width: 0;
  transition: $sidebar-transition;
  flex-direction: column;
  background-color: $white;

  @include media-breakpoint-down(md) {
    overflow-y: hidden;
  }
}

.ag-grid-table {
  height: calc(100vh - 277px);
  .ag-theme-alpine {
    height: auto !important;
    .ag-root-wrapper {
      height: 100% !important;
    }
  }
}

.ag-grid-group-table {
  height: calc(100vh - 238px);
  .ag-theme-alpine {
    height: auto !important;
    .ag-root-wrapper {
      height: 100% !important;
    }
  }
}

.ag-grid-table.with-tabs {
  height: calc(100vh - 292px);
}

.ag-grid-table.with-multi-tabs {
  height: calc(100vh - 332px);
}

.ag-grid-table.end-user-email-preference {
  height: calc(100vh - 378px);
}

.ag-grid-table.admin {
  height: calc(100vh - 200px);
}

.ag-grid-table.history {
  height: calc(100vh - 255px);
}
.ag-grid-table.reports {
  height: calc(100vh - 200px);
}

.ag-grid-table.email-domain {
  height: calc(100vh - 236px);
}

.ag-grid-table.user {
  height: calc(100vh - 380px);
}
.ag-grid-table.user-group {
  height: calc(100vh - 180px);
}

.mbx-12 {
  margin-bottom: 12px;
}
