.ug-tab {
  .tab-content {
    min-height: 26rem;
  }
}

/* For above 1024 Resolution monitor */
@media only screen and (min-width: 1281px) {
  .ug-tab {
    .tab-content {
      min-height: 32rem;
    }
  }
}
