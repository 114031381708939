.dataset-introduction-section {
  .dataset-intro-main {
    padding-top: 1rem;
    padding-right: 6.375rem;
    // border-right: 0.0625rem solid #dedede;
  }

  .dataset__overview-row {
    align-items: center;
    display: flex;
    flex-direction: row;
    padding-bottom: rem(20);
  }

  .dataset__overview-logo {
    width: fit-content;

    img {
      margin: 0;
      max-height: rem(50);

      @include breakpoint(tabletPortrait) {
        max-height: rem(60);
      }

      @include breakpoint(tabletLandscape) {
        max-height: rem(50);
      }

      @include breakpoint(desktop) {
        max-height: rem(75);
      }

      @include breakpoint(desktopXL) {
        max-height: rem(85);
      }
    }
  }

  .dataset__overview-flag {
    align-self: flex-start;
    margin-left: auto;

    .data-flag {
      position: relative;
    }
  }

  .dataset__overview-description {
    padding-bottom: rem(30);
  }

  .dataset__support-links {
    @include breakpoint(tabletPortrait) {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: 1fr 1fr;
    }

    @include breakpoint(tabletLandscape) {
      display: grid;
      grid-gap: 0;
      grid-template-columns: 1fr;
    }
  }

  .dataset__form {
    margin: 0;
    padding-left: 1.5rem;
    padding-top: 1.5625rem;

    &-email-address {
      @include font-akk(bold);

      color: $spg;
      font-size: $font-size-base;
      margin: rem(14) rem(10) rem(50) 0;
      text-decoration: none;
    }
  }

  .dataset__rat-button-wrapper {
    margin-bottom: rem(40);
    transition: transform 200ms ease-out, box-shadow 250ms ease-in;
    width: 100%;

    &--sticky {
      background-color: $white;
      bottom: 0;
      box-shadow: 0 rem(-5) rem(10) rgba(0, 0, 0, 0.1);
      left: 0;
      margin: 0;
      padding: rem(20);
      position: fixed;
      right: 0;
      z-index: 10;

      @include breakpoint(tabletPortrait) {
        padding: rem(20) rem(40);
      }
    }

    &--hidden {
      transform: translateY(100%);
    }
  }

  .ButtonLink {
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    font-size: $font-size-base;
    padding-left: 0;
    padding-right: 10px;
    text-decoration: underline;
  }

  .ButtonLink:hover,
  .ButtonLink:focus {
    text-decoration: underline;
  }

  .package-table thead {
    border-bottom: 2px solid black;
  }
  .package-table tr {
    border-bottom: 1px solid black;
  }
  .package-table td {
    padding: 8px;
  }
  .package-table ul {
    list-style-type: none;
  }
}
