@import './../../assets/scss/1-variables/app';

.tooltip-link {
  padding: 0 10px;
  position: absolute;
  background: $black;
  color: $white;
  height: 2rem;
  margin: 0;
  top: 4px;
  left: 37px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  z-index: 100;
}
.link-block {
  display: flex;
  align-items: center;
  justify-content: start;
  height: 100%;
}
