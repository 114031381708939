.filter-controls {
    margin-bottom: 0;
}

.filter-controls-panel-desktop {
    justify-content: space-between;
    padding-bottom: 0.5rem;
    padding-top: 1.5625rem;
}

.filter-controls-panel {
    display: flex;
    margin: 0 auto;
    padding-bottom: 1.25rem;
    padding-top: 1.25rem;
    width: 100%;
}

.page-section-inner {
    margin: 0 auto;
    // padding: 0 1.25rem;
}

.filters-dropdown {
    display: flex;
    flex-wrap: wrap;
    max-width: 75%;
}

.filter-button-text,
.filter-button:focus {
    color: $black;
}

.filter-button {
    font-family: $base-font-family;
    font-weight: $font-weight-normal;
    font-size: $font-size-base;
    line-height: $line-height-base;
    height: 3.625rem;
    background: $white;
    border: 0.0625rem solid $filter-menu-border;
    color: $filter-menu-text;
    margin: 0 -0.0625rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.75rem;
    transition-property: background-color, color;
    transition-timing-function: ease-in-out;
    transition-duration: .25s;

    &:active,
    &:hover {
        background-color: $white;
        border: 1px solid #dedede;
        color: $black;
    }
}