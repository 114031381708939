.ag-theme-alpine {
  font-family: 'Akk Pro', Arial, sans-serif;
  margin: 0.5rem 0 0 0;
}
.grid-btn-bar {
  text-align: right;
  margin-bottom: 0.5em;
}
.pr-custom {
  text-align: right;
  padding-right: 0.3em;
}
.margin-right-small {
  margin-right: 0.5em;
}
.ag-header-icon.ag-header-cell-menu-button {
  opacity: 1 !important;
}
.ag-header-icon.ag-header-cell-menu-button > .ag-icon.ag-icon-menu:before {
  font-weight: bold;
  font-size: 1.15rem;
}

.ag-menu-list .ag-menu-separator:nth-child(2) {
  display: none !important;
}

.ag-selection-checkbox.ag-invisible {
  display: none !important;
}

.ag-column-drop-horizontal {
  height: 35px !important;
}
.ag-cell-wrapper {
  height: 100%;
}
form.ag-filter-wrapper.ag-focus-managed {
  margin-bottom: 0px !important;
}
