@import './../../../assets/scss/1-variables/app';

.input-block {
  width: 100%;
  height: 70%;
  margin-top: 1.25%;
  border: 1px solid $gray-500;
}
.input-main {
  display: flex;
  align-items: center;
  height: 100%;
}
.aggrid-input-wrapper {
  display: flex;
  align-items: center;
}
.aggrid-input-border {
  border: 0.5px solid #b3b3b3 !important;
  margin: 1% 0;
  width: 100%;
  height: 75%;
  cursor: text;
  border-radius: 4px;
  padding: 0 5px 0 5px;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.aggrid-input-wrapper
  > .ag-cell-edit-wrapper
  > .ag-cell-editor
  > .ag-input-wrapper
  > input {
  border: 0.5px solid #b3b3b3 !important;
  margin: 3.5%;
  padding-right: 10px;
  width: 100%;
  height: 75% !important;
}
