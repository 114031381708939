.link-list {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;

    &__item {
        border-bottom: 1px solid $color-light-grey;
        font-size: $font-size-base;
        line-height: $line-height-xxl;
        padding: rem(10) 0;

        .links__text {
            font-weight: $font-weight-normal;
        }
    }

    .links__icon--internal {
        margin-top: rem(5);
    }
}
