.export-user-grid .ag-icon-filter {
  display: none !important;
}

.ag-grid-export-users-table {
  height: calc(100vh - 325px);
  .ag-theme-alpine {
    height: auto !important;
    .ag-root-wrapper {
      height: 100% !important;
    }
  }
}
