.navbar {
  // border-bottom: $navbar-border-bottom;
  box-shadow: $navbar-box-shadow;
  padding: 0 0.75rem;

  @include media-breakpoint-down(md) {
    width: 100vw;
  }
}

.input-group-navbar {
  min-width: rem(240px);
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-lg,
.navbar-expand > .container-md,
.navbar-expand > .container-sm,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}

.navbar-bg {
  background: $navbar-bg;
}

.navbar-align {
  body:not([data-sidebar-position='right']) & {
    margin-left: auto;
  }

  body[data-sidebar-position='right'] & {
    margin-right: auto;
  }
}

.navbar-brand {
  font-weight: $navbar-brand-font-weight;
  font-size: $navbar-brand-font-size;
  padding: $navbar-brand-padding-y $navbar-brand-padding-x;
  color: $navbar-brand-color;
  display: block;

  svg,
  .feather {
    color: $navbar-brand-icon-color;
    height: rem(24px);
    width: rem(24px);
    margin-left: -0.15rem;
    margin-right: 0.375rem;
    margin-top: -0.375rem;
  }
}

.nav-icon,
.nav-flag {
  padding: 0.1rem 0.8rem;
  display: block;
  font-size: $font-size-xl;
  color: $gray-600;
  transition: $transition-appearance-fast;
  line-height: $line-height-base;

  &:after {
    display: none !important;
  }

  &:hover,
  &.active {
    color: $primary;
  }

  svg,
  .feather {
    width: rem(20px);
    height: rem(20px);
  }
}

.nav-item {
  .indicator {
    background: $primary;
    box-shadow: $box-shadow;
    border-radius: 50%;
    display: block;
    height: 18px;
    width: 18px;
    padding: 1px;
    position: absolute;
    top: 0;
    right: rem(-8px);
    text-align: center;
    transition: top 0.1s ease-out;
    font-size: $font-size-sm;
    color: $white;
  }

  &:hover .indicator {
    top: rem(-4px);
  }

  a:focus {
    outline: 0;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .navbar .avatar {
    max-height: rem(47px);
  }
}

@include media-breakpoint-down(sm) {
  .navbar {
    padding: 0.75rem;
  }

  .nav-icon {
    padding: 0.1rem 0.75rem;
  }

  .dropdown,
  .dropleft,
  .dropright,
  .dropup {
    position: inherit;
  }

  .navbar-expand .navbar-nav .dropdown-menu-lg {
    min-width: 100%;
  }

  .nav-item .nav-link:after {
    display: none;
  }
}

.nav-flag img {
  border-radius: 50%;
  width: rem(20px);
  height: rem(20px);
  object-fit: cover;
}

.navbar-nav {
  direction: ltr;
}

.navbar input {
  direction: ltr;
}

.navbar .form-control {
  max-width: rem(200px);
}

.navbar-light {
  .navbar-nav {
    .nav-link {
      font-family: $base-font-family;
      font-weight: 700;
      color: $black;
      border-right: rem(1px) solid $gray-250;
      font-size: $font-size-lg;
      padding: 0 0.55rem;
      cursor: pointer;
    }

    .nav-link.active {
      color: $spg !important;
    }

    .nav-link:hover {
      color: $black;
    }
  }
}

@include media-breakpoint-down(lg) {
  .navbar-light {
    .navbar-nav {
      .nav-link {
        border-right: 0px;
        border-bottom: rem(1px) solid $gray-250;
        padding: 0.25rem 0;
      }
    }
  }
}

.no-border-right {
  border-right: 0px solid !important;
}
.truncate.user {
  max-width: rem(100);
}

.navbar-logout {
  font-family: $base-font-family !important;
  font-size: $font-size-base !important;
  display: inline-flex;
  align-items: center;
}

.logo-section {
  display: flex;
  align-items: center;

  .logo-img {
    max-height: rem(50px);
    padding-right: 1rem;
    border-right: 1px solid $gray-250;
  }

  .logo-sub-heading {
    font-family: $base-font-family;
    font-style: $font-style-normal;
    font-weight: 700;
    font-size: $font-size-xl;
    line-height: 135%;
    color: $gray-650;
  }
  .logo-beta-text {
    color: #d6002a;
    font-weight: 700;
    font-size: 0.85rem;
    margin-left: 5px;
  }
}
@include media-breakpoint-down(sm) {
  .logo-section {
    .logo-img {
      max-height: rem(25px);
      padding-right: 0.5rem;
    }

    .logo-sub-heading {
      font-size: $font-size-xsm;
    }
    .logo-beta-text {
      font-size: 0.5rem;
    }
  }
  .navbar-brand {
    margin-right: 0.25rem;
  }
}
.bell-icon {
  position: absolute;
  width: rem(15px);
  height: rem(12px);
  font-size: $font-size-sm;
  color: $red-500;
}

.option-list {
  right: 15px;
  width: max-content;
  @include media-breakpoint-down(lg) {
    left: 15px;
  }
}
.option-list-item {
  color: #000000;
  font-size: 17px;
}
.option-list-item-text {
  cursor: pointer;
}
.nav-tabs .nav-link {
  padding: 0.5rem 0.5rem;
}

.cca-nav > li {
  &:not(:nth-child(1)):not(:nth-child(4)):not(:nth-child(5)) {
    pointer-events: none;
    opacity: 50%;
  }
  &:first-child > a {
    color: $spg !important;
  }
}
.my-subscription-nav > li {
  &:not(:nth-child(1)):not(:nth-child(3)) {
    pointer-events: none;
    opacity: 50%;
  }
  &:first-child > a {
    color: $spg !important;
  }
}
.menu-disable {
  pointer-events: none;
  opacity: 50%;
}
