@import './../../../assets/scss/1-variables/app';

.no-scroll .ag-center-cols-viewport {
    overflow: hidden !important;
}
    
.no-scroll .ag-body-horizontal-scroll-viewport {
    overflow-x: hidden !important;
}

.no-scroll .ag-focus-managed {
    border: 0px;
}

.clone-user-entitlements-subtitle {
    letter-spacing: $letter-spacing-md;
    font-size: $font-size-lg;
    margin-left: 0.25rem;
  }