.avatar {
  width: 1.4375em;
  height: 1em;
  vertical-align: top;
  margin-left: 0.5rem;
  color: $gray-600;
}

.active > svg {
  color: $spg;
}
