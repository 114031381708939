@mixin placeholder($color, $size: "", $font-family: $base-font-family, $font-style: normal, $font-weight: 400) {
    &::-webkit-input-placeholder {
        color: $color;
        font-size: $size;
        font-family: $font-family;
        font-style: $font-style;
        font-weight: $font-weight;
    }

    &:-moz-placeholder {
        color: $color;
        font-size: $size;
        font-family: $font-family;
        font-style: $font-style;
        font-weight: $font-weight;
    }

    &::-moz-placeholder {
        color: $color;
        font-size: $size;
        font-family: $font-family;
        font-style: $font-style;
        font-weight: $font-weight;
    }

    &:-ms-input-placeholder {
        color: $color;
        font-size: $size;
        font-family: $font-family;
        font-style: $font-style;
        font-weight: $font-weight;
    }
}

// How to include this into scss. Insert the below code snippet direct into the scss file or className
// In case of absence of parameters, default values will be applied
// @include placeholder(color, font-size, font-family, font-style, font-weight); 