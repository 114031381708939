@import './../../assets/scss/1-variables/app';

.action-item-seprator {
  font-size: medium;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}
.user-grid-icons,
.email-label {
  &:hover {
    cursor: pointer;
  }
}

.info-icon {
  margin-bottom: 0.5rem;
  margin-left: 0.6rem;
  height: 1.2rem;
  width: 1.2rem;
  cursor: pointer;
}

.default-grey-color {
  color: $gray-550;
}
.email-label {
  color: $blue;
}

.icon-divider {
  position: relative;
  margin-right: 1.6rem;
  &:after {
    content: '';
    position: absolute;
    background-color: $gray-250;
    height: 1.75rem;
    display: block;
    width: 0.04rem;
    top: -0.4rem;
    left: 1.9rem;
  }
  &:last-child {
    margin-right: 0;
  }
  &:last-child:after {
    display: none;
  }
  svg {
    fill: $gray-550;
  }
  svg.icon-active {
    fill: $link-blue;
  }
  svg.approve-icon {
    stroke: $gray-550;
    stroke-width: 0.7px;
  }
  svg.icon-active.approve-icon {
    stroke: $link-blue;
    stroke-width: 0.7px;
  }
  svg.reject-icon {
    stroke: $gray-550;
    stroke-width: 1.7px;
  }
  svg.icon-active.reject-icon {
    stroke: $link-blue;
    stroke-width: 1.7px;
  }
}

.cursor-default {
  cursor: default !important;
}
input[type='text'].search-box::-webkit-input-placeholder {
  font-style: $font-style-italic;
}

.select-all-checkbox {
  width: 1rem;
  height: 0.95rem;
  margin-right: 12px;
  outline: auto;
  color: #999;
  margin-left: 1px;
  opacity: 0.9;
}
