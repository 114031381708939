@include breakpoint(tabletPortrait) {
  .modal-size-md {
    max-width: rem(600px);
  }
}

@include breakpoint(tabletLandscape) {
  .modal-size-md {
    max-width: rem(650px);
  }
}

@include breakpoint(desktop) {
  .modal-size-md {
    max-width: rem(900px);
  }
}

@include breakpoint(desktopL) {
  .modal-size-md {
    max-width: rem(1140px);
  }
}
