.sticky-tooltip {
  position: fixed;
  display: flex;
  align-items: center;
  padding: 3px 15px 3px 10px;
  font-size: 0.75rem;
  background-color: #464646;
  color: #fff;
  max-width: 430px;
  height: 40px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  overflow-y: hidden;
  overflow-x: hidden;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  pointer-events: auto;
  z-index: 9999;
  right: 3.5%;
  top: 1%;
}

.close-button {
  position: absolute;
  top: 2px;
  right: 7px;
  background: none;
  border: none;
  font-size: 13px;
  cursor: pointer;
  color: #fff;
  padding: 0;
}

.close-button:hover {
  color: #ccc;
}
