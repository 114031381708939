footer.footer {
  margin-top: auto;
  background: $footer-background;
  border-top: 1px solid $footer-border-top-color;
  padding: 0 $spacer;
  direction: ltr;
  position: sticky;
  bottom: 0;
  z-index: 3;

  ul {
    margin-bottom: 0;
  }

  .footer-links {
    font-size: $font-size-sm;
    color: $gray-650;
    text-decoration: $link-hover-decoration;
    line-height: $line-height-sm;
    font-weight: $font-weight-normal;
  }
  .copyright-text {
    margin-bottom: $spacer * 0.5;
    @extend .footer-links;

    text-decoration: $link-decoration;
  }

  .list-inline-item:not(:last-child) {
    margin-right: $spacer * 0.25;
  }

  .footer-logo > img {
    width: 50%;
    margin-top: $spacer * 0.7;
  }
  .footer-logo::before {
    content: '';
    display: block;
    width: rem(1.2);
    background-color: $footer-sep-bg;
    height: $spacer * 1.85;
    float: left;
    top: $spacer * 0.75;
    position: relative;
    margin-right: $spacer * 0.75;
  }

  @include media-breakpoint-down(md) {
    width: 100vw;
  }
}
