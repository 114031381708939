@import './../../assets/scss/1-variables/app';

.chips-block > .p-component > .p-chips-multiple-container {
  min-height: 5.5rem;
  align-items: flex-start;
}

.chips-block > .p-component > .p-chips-multiple-container > .p-chips-token {
  margin-bottom: 0.5rem;
}

.error-text > .p-error {
  font-size: $font-size-base;
}

.add-edit-manage-email-domain-subtitle {
  letter-spacing: $letter-spacing-md;
  font-size: $font-size-lg;
  margin-left: 0.25rem;
}

.add-edit-manage-email-domain-info {
  letter-spacing: $letter-spacing-md;
  font-size: $font-size-lg;
}

.modal-b > .modal-body {
  padding-bottom: 0;
}
.chip-label {
  margin-bottom: 0.25rem;
}
.invalid-chip > ul > li:nth-last-child(2) {
  border: 1px solid red;
}
svg.icon-active {
  fill: $link-blue;
}

.mt-75{
  margin-top: .8rem;
}
