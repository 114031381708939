.display-vcenter {
    display: flex;
    align-items: center;
}

.flex-wrapped {
    flex-wrap: wrap;
}

.flex-column {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.flex-centered {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-display {
    display: flex;
}

.flex-full {
    flex: 1
}

.flex-1 {
    flex: 1
}

.flex-2 {
    flex: 2
}