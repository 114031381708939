.alert-title-main {
  font-weight: 400;
  font-size: rem(36px);
  line-height: $line-height-sm;
  text-align: center;
  letter-spacing: $letter-spacing-md;
  margin-bottom: 0.25rem;
}

.alert-title {
  min-height: rem(37px);
  font-weight: $font-weight-normal;
  font-size: $font-size-xl;
  text-align: center;
  letter-spacing: $letter-spacing-md;
  margin-bottom: 0.5rem;
}

.alert-box {
  max-width: rem(450px) !important;
  padding: rem(8px) rem(16px) rem(16px);
}

.alert-max-width {
  max-width: rem(580px) !important;
}

.alert-icon-body {
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.alert-icon-img {
  height: rem(34px);
  width: rem(34px);
}

.alert-btn {
  font-weight: 700;
  font-size: $font-size-base;
  text-align: center;
  text-transform: uppercase;
  padding: 0.4rem 1rem;
  padding-bottom: 0.25rem;
}

.alert-close-btn {
  height: rem(7px);
  width: rem(7px);
  position: absolute;
  top: rem(12px);
  right: rem(12px);
}

.alert-box-shadow {
  box-shadow: $box-shadow-xl;
}

.alert-z-index {
  z-index: 1055;
}

// Custom size for different pages

.alert-export-success-max-width {
  max-width: rem(700px) !important;
}

.groupEntitlementsAttemptedUpdate-alert {
  max-width: 35.125rem !important;
}

.contractDescriptionPreference-alert {
  max-width: rem(800px) !important;
}

.duplicateContractDescription-alert {
  max-width: rem(800px) !important;
}

.resetDisplayOption-alert {
  max-width: rem(1000px) !important;
}
