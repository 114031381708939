.category_name {
  font-family: $base-font-family;
  font-style: $font-style-normal;
  font-weight: 700;
  font-size: $font-size-2xl;
  display: inline-block;
  margin-top: 1rem;
}

.tile-section {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 0rem;
  gap: 1rem;
}

.tile-grid {
  display: flex;
  flex-flow: row wrap;
}

.show-all {
  background-color: $black;
  width: 99%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  font-weight: bold;
  font-size: $font-size-xl;
  font-family: $base-font-family;
  height: 3em;
  border-radius: 0.375em;
  margin-bottom: 0.4em;
  cursor: pointer;
  position: relative;
}

.show-min {
  height: 22.75em;
  overflow: hidden;
}

.page-header {
  color: $spg;
  font-size: $font-size-2xl;
  font-weight: $font-weight-bold;
}
@include media-breakpoint-down(sm) {
  .page-header {
    font-size: $font-size-xl;
  }
}

.search-container {
  display: flex;
  border: none;
  height: 5rem;
  padding: 0 0.7em;
  margin: 1em 0em;
  background-color: $smoke-white;
  align-items: center;
  input:focus {
    outline: none;
  }
  .search-input::placeholder {
    font: 1em $base-font-family;
  }
  .search-input {
    border: none;
    background-color: $smoke-white;
    padding-left: 0.5em;
    height: 2em;
    flex: 1;
    font-size: $font-size-xl;
  }
}

.packages-dropdown {
  position: absolute;
  display: flex;
  justify-content: end;
  top: rem(-11);
  right: 0;
}
.dropdown-menu.show {
  width: 100%;
}
@include media-breakpoint-down(lg) {
  .packages-dropdown {
    display: block;
    position: static;
  }
}

// Bootstrap Tabs
.tabs-main {
  border: rem(1px) solid $gray-550;
  border-radius: 0;
  margin-top: rem(6px);
  position: relative;

  .nav-item {
    button {
      color: $black;
      font-size: 0.95rem;
      font-weight: $font-weight-normal;
    }

    .active {
      background-color: $gray-550;
      color: $black;
      border-radius: 0px;
    }
  }

  .nav-link:hover {
    color: $black;
    text-decoration: none;
  }
  .search-results {
    padding-top: 2em;
    h3 {
      margin-bottom: rem(32);
    }

    ul li {
      margin-bottom: rem(8);
      font-size: $font-size-xl;
    }
  }
}
