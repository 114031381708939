.pad-xy {
    padding: 0.25em !important;
    &-0 {
        padding: 0 !important;
    }
    &-2 {
        padding: 0.5em !important;
    }
    &-3 {
        padding: 0.75em !important;
    }
    &-4 {
        padding: 1em !important;
    }
    &-6 {
        padding: 1.5em !important;
    }
    &-8 {
        padding: 2em !important;
    }
}

.pad-x {
    padding-left: 0.25em !important;
    padding-right: 0.25em !important;
    &-0 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    &-2 {
        padding-left: 0.5em !important;
        padding-right: 0.5em !important;
    }
    &-3 {
        padding-left: 0.75em !important;
        padding-right: 0.75em !important;
    }
    &-4 {
        padding-left: 1em !important;
        padding-right: 1em !important;
    }
    &-6 {
        padding-left: 1.5em !important;
        padding-right: 1.5em !important;
    }
    &-8 {
        padding-left: 2em !important;
        padding-right: 2em !important;
    }
}

.pad-y {
    padding-top: 0.25em !important;
    padding-bottom: 0.25em !important;
    &-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    &-2 {
        padding-top: 0.5em !important;
        padding-bottom: 0.5em !important;
    }
    &-3 {
        padding-top: 0.75em !important;
        padding-bottom: 0.75em !important;
    }
    &-4 {
        padding-top: 1em !important;
        padding-bottom: 1em !important;
    }
    &-6 {
        padding-top: 1.5em !important;
        padding-bottom: 1.5em !important;
    }
    &-8 {
        padding-top: 2em !important;
        padding-bottom: 2em !important;
    }
}

.pad-top {
    padding-top: 0.25em !important;
    &-0 {
        padding-top: 0 !important;
    }
    &-2 {
        padding-top: 0.5em !important;
    }
    &-3 {
        padding-top: 0.75em !important;
    }
    &-4 {
        padding-top: 1em !important;
    }
    &-6 {
        padding-top: 1.5em !important;
    }
    &-8 {
        padding-top: 2em !important;
    }
}

.pad-bottom {
    padding-bottom: 0.25em !important;
    &-0 {
        padding-bottom: 0 !important;
    }
    &-2 {
        padding-bottom: 0.5em !important;
    }
    &-3 {
        padding-bottom: 0.75em !important;
    }
    &-4 {
        padding-bottom: 1em !important;
    }
    &-6 {
        padding-bottom: 1.5em !important;
    }
    &-8 {
        padding-bottom: 2em !important;
    }
}

.pad-left {
    padding-left: 0.25em !important;
    &-0 {
        padding-left: 0 !important;
    }
    &-2 {
        padding-left: 0.5em !important;
    }
    &-3 {
        padding-left: 0.75em !important;
    }
    &-4 {
        padding-left: 1em !important;
    }
    &-6 {
        padding-left: 1.5em !important;
    }
    &-8 {
        padding-left: 2em !important;
    }
}

.pad-right {
    padding-right: 0.25em !important;
    &-0 {
        padding-right: 0 !important;
    }
    &-2 {
        padding-right: 0.5em !important;
    }
    &-3 {
        padding-right: 0.75em !important;
    }
    &-4 {
        padding-right: 1em !important;
    }
    &-6 {
        padding-right: 1.5em !important;
    }
    &-8 {
        padding-right: 2em !important;
    }
}

.marg-xy {
    margin: 0.25em !important;
    &-0 {
        margin: 0 !important;
    }
    &-2 {
        margin: 0.5em !important;
    }
    &-3 {
        margin: 0.75em !important;
    }
    &-4 {
        margin: 1em !important;
    }
    &-6 {
        margin: 1.5em !important;
    }
    &-8 {
        margin: 2em !important;
    }
}

.marg-x {
    margin-left: 0.25em !important;
    margin-right: 0.25em !important;
    &-0 {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    &-2 {
        margin-left: 0.5em !important;
        margin-right: 0.5em !important;
    }
    &-3 {
        margin-left: 0.75em !important;
        margin-right: 0.75em !important;
    }
    &-4 {
        margin-left: 1em !important;
        margin-right: 1em !important;
    }
    &-6 {
        margin-left: 1.5em !important;
        margin-right: 1.5em !important;
    }
    &-8 {
        margin-left: 2em !important;
        margin-right: 2em !important;
    }
}

.marg-y {
    margin-top: 0.25em !important;
    margin-bottom: 0.25em !important;
    &-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    &-2 {
        margin-top: 0.5em !important;
        margin-bottom: 0.5em !important;
    }
    &-3 {
        margin-top: 0.75em !important;
        margin-bottom: 0.75em !important;
    }
    &-4 {
        margin-top: 1em !important;
        margin-bottom: 1em !important;
    }
    &-6 {
        margin-top: 1.5em !important;
        margin-bottom: 1.5em !important;
    }
    &-8 {
        margin-top: 2em !important;
        margin-bottom: 2em !important;
    }
}

.marg-top {
    margin-top: 0.25em !important;
    &-0 {
        margin-top: 0 !important;
    }
    &-2 {
        margin-top: 0.5em !important;
    }
    &-3 {
        margin-top: 0.75em !important;
    }
    &-4 {
        margin-top: 1em !important;
    }
    &-6 {
        margin-top: 1.5em !important;
    }
    &-8 {
        margin-top: 2em !important;
    }
}

.marg-bottom {
    margin-bottom: 0.25em !important;
    &-0 {
        margin-bottom: 0 !important;
    }
    &-2 {
        margin-bottom: 0.5em !important;
    }
    &-3 {
        margin-bottom: 0.75em !important;
    }
    &-4 {
        margin-bottom: 1em !important;
    }
    &-6 {
        margin-bottom: 1.5em !important;
    }
    &-8 {
        margin-bottom: 2em !important;
    }
}

.marg-left {
    margin-left: 0.25em !important;
    &-0 {
        margin-left: 0 !important;
    }
    &-2 {
        margin-left: 0.5em !important;
    }
    &-3 {
        margin-left: 0.75em !important;
    }
    &-4 {
        margin-left: 1em !important;
    }
    &-6 {
        margin-left: 1.5em !important;
    }
    &-8 {
        margin-left: 2em !important;
    }
}

.marg-right {
    margin-right: 0.25em !important;
    &-0 {
        margin-right: 0 !important;
    }
    &-2 {
        margin-right: 0.5em !important;
    }
    &-3 {
        margin-right: 0.75em !important;
    }
    &-4 {
        margin-right: 1em !important;
    }
    &-6 {
        margin-right: 1.5em !important;
    }
    &-8 {
        margin-right: 2em !important;
    }
}