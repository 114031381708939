.dropdown-disabled button {
  background-color: #e2e8ee !important;
  opacity: 1 !important;
  cursor: not-allowed !important;
}

.disabled-option {
  pointer-events: none;
  opacity: 0.4;
}
