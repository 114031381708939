/// Buttons
//

$button-hover-transition: 0.25s cubic-bezier(0.06, 0.72, 0.93, 1);

button {
    @include font-akk(bold);

    background-color: transparent;
    border: 1px solid $black;
    cursor: pointer;
    display: inline-block;
    font-size: $font-size-lg;
    line-height: $line-height-base;
    padding: rem(15) rem(34);
    text-align: center;
    text-decoration: none;
    transition:
        color $button-hover-transition,
        background-color $button-hover-transition,
        border-color $button-hover-transition;

    // &:hover {
    //     background-color: $black;
    //     color: $white;
    // }
}

// -----------------------
// BUTTON CLASSES
// -----------------------
.button {
    @extend button;

    &__icon {
        margin-left: rem(10);
    }

    &__title {
        font-size: $font-size-xl;
    }

    //
    // PRIMARY: Red filled button
    //
    &--primary {
        background-color: $spg;
        border-color: $spg;
        color: $white;

        &:hover,
        &:focus,
        &:active {
            background-color: $black;
            border-color: $black;
            color: $white;
        }
    }

    //
    // SECONDARY: Black filled button
    //
    &--secondary {
        background-color: $black;
        border-color: $black;
        color: $white;

        &:hover,
        &:focus,
        &:active {
            background-color: $color-boulder;
            border-color: $color-boulder;
            color: $white;
        }
    }

    //
    // TERTIARY: Outline only
    //
    &--tertiary {
        background-color: $white;
        border-color: $black;
        color: $black;

        &:hover,
        &:focus,
        &:active {
            background-color: $black;
            border-color: $black;
            color: $white;
        }
    }

    &--tertiary-reversed {
        background-color: transparent;
        border-color: $white;
        color: $white;

        &:hover,
        &:focus,
        &:active {
            background-color: $white;
            border-color: $white;
            color: $black;
        }
    }

    //
    // CONTROL BUTTON: Used to action filter dropdown
    //
    &--select-control {
        @include font-akk(bold);

        background-color: $white;
        border-color: $color-light-grey;
        color: $color-boulder;
        display: flex;
        justify-content: center;
        line-height: $line-height-base;
        padding: rem(14) rem(20);
        transition: color 0.25s cubic-bezier(0.06, 0.72, 0.93, 1), background-color 0.25s cubic-bezier(0.06, 0.72, 0.93, 1);

        &:active,
        &:hover {
            background-color: $white;
            border: 1px solid $color-light-grey;
            color: $black;
        }

        &:focus {
            background-color: $white;
            color: $color-boulder;
        }

        &:disabled {
            border: 1px solid lighten($color-light-grey, 80%);
            color: lighten($color-boulder, 40%);
            cursor: default;
        }

        svg {
            margin-right: rem(7);
        }
    }

    //
    // Disabled button styles
    //
    &:disabled {
        background-color: $color-grey-80;
        border-color: $color-grey-80;
        color: $white;
        cursor: default;

        &:hover,
        &:focus,
        &:active {
            background-color: $color-grey-80;
            color: $white;
        }
    }

    &:disabled.button--secondary {
        background-color: $white;
        border-color: $color-grey-80;
        color: $color-grey-80;

        &:hover,
        &:focus,
        &:active {
            background-color: $white;
            color: $color-grey-80;
        }
    }

    &:disabled.button--tertiary-reversed {
        background-color: transparent;
    }

    //
    // Other button modifiers
    //
    &--titled {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: rem(38) rem(32);

        span {
            align-items: center;
            display: flex;
        }
    }

    &--uppercase {
        text-transform: uppercase;
    }

    &--fullwidth {
        width: 100%;
    }

    &--icon-only {
        border: 0;
        line-height: $line-height-xsm;
        padding: 0;

        &:active,
        &:focus,
        &:hover {
            color: $spg;
        }
    }
}
