body, html, #root {
  height: 100%;
}

html {
  scroll-behavior: smooth;
  scrollbar-color: $gray-500;
  scrollbar-width: none;
}
body::-webkit-scrollbar {
  display: none;
}

body {
  overflow-y: scroll;
  opacity: 1 !important;
}
::-webkit-scrollbar {
  width: rem(8);
  height: rem(8);
  background-color: $white;
}
::-webkit-scrollbar-thumb {
  background: $gray-550;
  border-radius: 5px;
  height: rem(50);
}
// ::-webkit-scrollbar-track {
//   margin-top: 10px;
//   margin-bottom: 10px;
// }

body[data-sidebar-position=right] {
  direction: rtl;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html {
    overflow-x: hidden;
  }
}
