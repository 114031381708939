@import './../../assets/scss/1-variables/app';
@import './../../assets//scss/7-functions/units';
.date-picker-container > .react-datepicker-wrapper {
  margin-right: rem(16);
  float: left;
}
@media only screen and (max-width: 992px) {
  .date-picker-container > .react-datepicker-wrapper {
    margin-right: 0.2rem;
    width: 47%;
  }
}
@media only screen and (max-width: 600px) {
  .date-picker-container > .react-datepicker-wrapper {
    width: 48%;
  }
  .react-datepicker-wrapper {
    width: 50%;
  }
}

.date-picker-container
  > .react-datepicker-wrapper
  > .react-datepicker__view-calendar-icon
  > .react-datepicker__calendar-icon {
  width: 2em !important;
  height: 2.2em;
}
.react-datepicker__view-calendar-icon input {
  width: 100%;
}
.notes-text-label {
  font-size: 0.8rem;
  float: left;
}
.notes-asterisk-label {
  padding-right: 5px;
  position: relative;
  bottom: 2px;
  color: $spg;
}
.date-picker-container {
  display: flex;
  align-items: center;
}
.export-icon-block {
  display: flex;
  align-items: center;
  justify-content: end;
}