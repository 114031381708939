@import './../../assets/scss/1-variables/app';
.sub-heading {
  font-weight: 600;
}
.dataset-introduction-section {
  .dataset__overview-description {
    div p {
      margin-bottom: 0.25em;
    }
  }
  .distributor-sub-detail {
    div p {
      margin-bottom: 0.25em;
    }
    .html-content {
      white-space: normal;
    }
  }
  strong {
    font-weight: 600;
  }
}
.page-section > .html-content {
  white-space: normal;
}
.snowflake-text {
  color: red;
}
.link-text {
  color: blue;
  text-decoration: none;
}
.back-text {
  font-size: $font-size-lg;
  color: $spg;
  text-transform: uppercase;
  font-weight: $headings-font-weight;
}
