.cursor-grab {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.cursor-pointer {
  cursor: pointer;
}

.pointer-all {
  pointer-events: all !important;
}

.pointer-none {
  pointer-events: none !important;
}

.default-cursor {
  cursor: default !important;
}