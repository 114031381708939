.user-form-content {
  padding: 1rem;
}

.user-form-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: rem(0px) rem(0px) rem(24px);
  gap: rem(24px);

  width: rem(696px);
  height: rem(304px);
}

.user-form-title {
  width: 100%;
  font-family: $base-font-family;
  font-style: $font-style-normal;
  font-weight: $font-weight-normal;
  font-size: rem(24px);
  letter-spacing: $letter-spacing-md;
}

.user-info-form-label{
  margin-top: rem(1px);
  margin-bottom: rem(9px);
}

.user-form-label {
  font-family: $base-font-family;
  font-style: $font-style-normal;
  font-weight: $font-weight-normal;
  font-size: $font-size-base;
  line-height: $line-height-xsm;
  color: $gray-35;
}

.market-email-label {
  font-size: rem(14px);
  max-width: 96% !important;
}

.user-form-input {
  @include placeholder(
    $gray-650,
    rem(15px),
    $base-font-family,
    $font-style-italic
  );

  padding-left: rem(6px);
  border: rem(1px) solid $gray-35;

  &:focus {
    color: $black;
    background-color: $white;
    border-color: $gray-550;
    outline: 0;
    box-shadow: 0 0 0 0.1rem $gray-550;
  }
}

.user-dropdown {
  height: rem(38px);
  color: $gray-650;
  padding-top: rem(9px);
  padding-left: rem(6px);
  font-family: $base-font-family;
  font-style: $font-style-normal;
  font-weight: $font-weight-normal;
  font-size: $font-size-sm;
  border: rem(1px) solid $gray-35 !important;
  border-radius: rem(3px) !important;
}

.form-select > option.user-dd-select-menu {
  width: 100%;
  height: 16rem;
  overflow: scroll;
  padding: 1rem;
  max-width: rem(300px);

  &:-webkit-scrollbar {
    width: rem(8px);
    outline: rem(1px) solid slategrey;
  }
}

.user-dropdown-menu {
  width: 100%;
  // height: 16rem;
  // overflow: scroll;

  &:-webkit-scrollbar {
    width: rem(8px);
    outline: rem(1px) solid slategrey;
  }
}

.user-dropmenu-list {
  width: 100%;
  display: flex;
  align-items: center;
  padding: rem(8px) rem(12px);
}

.user-list-content {
  width: 100%;
  border-bottom: rem(1px) solid $gray-550;
}

.user-dropmenu-title {
  font-size: $font-size-lg;
}

.user-down-arrow {
  float: right;
  top: rem(3px) !important;
}

.user-save-btn-body {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.user-save-btn {
  min-width: rem(121px);
  height: rem(36px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-save-txt {
  font-family: $base-font-family;
  font-size: $font-size-sm;
  text-transform: uppercase;
  color: $white;
}

.user-dropmenu-selection {
  display: inherit;
  width: 90%;
  font-family: $base-font-family;
  font-style: $font-style-normal;
  font-weight: $font-weight-normal;
  font-size: $font-size-sm;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $black;
}
.account-label {
  cursor: pointer;
  font-size: $font-size-base;
}
.account-dropdown {
  display: flex;
  align-items: center;
  font-size: $font-size-base;
  padding: 0.35rem 0.375rem;
  color: $gray-700;
}
.bg-black {
  background: $black;
  border-color: $black;
}

.form-input-error {
  font-family: $base-font-family;
  font-size: $font-size-base;
  color: $spg;
}

// Common CSS
.asterik {
  color: $spg;
  font-size: $font-size-base;
  position: relative;
  bottom: rem(5px);
  left: rem(1px);
}

.btn-opacity:hover {
  opacity: 0.5;
}

.form-select:focus {
  color: #000;
  background-color: #fff;
  border-color: #bfbfbf;
  outline: 0;
  box-shadow: 0 0 0 0.1rem #bfbfbf;
}

.btn-dark:disabled {
  pointer-events: all;
  opacity: 0.5;
  cursor: not-allowed;
}

.modal-row {
  margin-top: rem(20px);
}
.modal-row.entitlement-fix {
  margin-top: rem(40);
}

.modal-row.group-mapped-entitlement-fix {
  margin-top: rem(50);
}

.new-selected-entitlement-row {
  background-color: #98fb98 !important;
}

.unselected-entitlement-row {
  background-color: #f26b66 !important;
}

.edit-user-subtitle {
  letter-spacing: $letter-spacing-md;
  font-size: $font-size-lg;
  margin-left: 0.25rem;
}

li > button:disabled {
  pointer-events: all !important;
  opacity: 0.5;
  cursor: not-allowed !important;
}

.custom-dropdown > .dropdown > .btn-light {
  width: 100%;
  background: $white;
  text-align: left;
  color: $gray-700;
  font-weight: $font-weight-normal;
  border: rem(1px) solid $gray-35;
  border-radius: $border-radius;
  padding-left: $spacer * 0.375;
}
.label-truncate {
  display: inline-block;
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* For 1024 Resolution laptop */
@media only screen and (min-width: 1025px) {
  .custom-dropdown > .dropdown > .dropdown-menu.show {
    width: 100%;
    max-height: rem(155);
    overflow: auto;
  }
}
/* For above 1024 Resolution monitor */
@media only screen and (min-width: 1281px) {
  .custom-dropdown > .dropdown > .dropdown-menu.show {
    max-height: rem(275);
  }
  .custom-dropdown {
    margin-bottom: 1.5rem !important;
  }
  .user-form-label {
    line-height: $line-height-sm;
  }

  .update-btn {
    // margin-bottom: 1rem;
  }
  .user-form-input {
    margin-bottom: 0.75rem;
  }
}
/* (2048x1536) 125% resolution */
@media only screen and (min-device-width: 1536px) and (max-device-width: 2048px) {
  .custom-dropdown > .dropdown > .dropdown-menu.show {
    max-height: rem(250);
  }
}
.custom-dropdown > .dropdown > .dropdown-menu.show > .dropdown-item {
  padding: $spacer * 0.25 0 !important;
}
.email-preference-note-label {
  font-size: 0.8rem;
  font-family: 'Akk Pro', Arial, sans-serif;
}
.pt-2-5 {
  padding-top: 0.6rem;
}
