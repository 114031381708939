a {
    color: inherit;
    display: inline-block;
    // text-decoration: underline;
    transition: color 0.2s ease;

    &:hover,
    &:focus,
    &:active {
        color: $spg;
    }
}

$link-icon-size: rem(24);

//
// ANCHOR CLASSES
//
.links {
    @extend a;
    @include font-akk(bold);

    border: 0;
    text-decoration: none;

    &--secondary { // Normal font, underline
        @include font-akk(regular);

        text-decoration: underline;
    }

    &--alternative { // Coloured
        color: $spg;

        &:hover,
        &:focus,
        &:active {
            color: $black;
        }
    }

    &--uppercase {
        font-size: $font-size-base;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    &--underline {
        text-decoration: underline;
    }

    &--fullwidth {
        display: block;
    }

    &--nowrap {
        .links__text {
            white-space: nowrap;
        }
    }

    &--external {
        display: inline-block;
        position: relative;

        &::after {
            // background-image: url(../assets/svg/icons/external-link.svg);
            background-size: $link-icon-size;
            content: '';
            display: inline-block;
            height: $link-icon-size;
            margin-left: rem(3);
            position: relative;
            vertical-align: middle;
            width: $link-icon-size;

            &:hover {
                color: $spg;
            }
        }
    }

    &--icon,
    &__icon {
        align-items: center;
        display: inline-flex;
        position: relative;
        vertical-align: top;

        &--external {
            font-size: inherit;
            margin-left: rem(3);
            margin-top: rem(-2);
            width: rem(20);
        }

        &--internal {
            height: rem(10);
            margin-left: rem(10);
            margin-top: rem(-2);
            width: rem(9);
        }

        &.links--fullwidth {
            display: block;

            .links__icon--external {
                height: rem(24);
                width: rem(24);
            }

            .links__icon--internal {
                height: rem(14);
                width: rem(9);
            }

            .links__text {
                display: inline-block;
                max-width: calc(100% - #{rem(28)});
                width: 100%;
            }
        }
    }
}
