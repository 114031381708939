@import './../../../assets/scss/1-variables/app';

.user-registartion-section {
  padding: 0rem 0.5rem;
  background: $color-tint-grey;
  border-radius: 0.3rem;
  box-shadow: $box-shadow-2xl;
}
@media only screen and (min-width: 1281px) {
  .user-registration-location-text {
  margin-top:-15px;
  }
  }

.centered-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%; 
  text-align: center;
}
